import { useEffect, useState } from 'react';
import { Link, NavLink,useLocation  } from 'react-router-dom';
import useStore from '../../state/useStore';
import Styles from './start.module.css';
import Header from '../../components/header/header';
import RefratechnikEmployeeJobCode from '../../api/jobcode'
function Start() {
  const jobcode = useStore(state => state.jobcode);
  const loadJobCode = useStore(state => state.loadJobCode);
  const location = useLocation();

useEffect(()=> {
loadJobCode();
},[]);



  return (
    <div className="App">
      <Header/>
      
<div className={Styles.outerbox}>
<img src="/pics/Refratechnik.svg" />
<h1>
Rechtskataster   
</h1>
<h2>
​Herzlich Willkommen auf den Seiten des Refratechnik Rechtskatasters
</h2>
<h3>
    Ihr Stellenkürzel: {jobcode}
</h3>
<div className={Styles.boxbuttons}>
    <div className={Styles.buttonbox}>
    <Link to="/gesetze">
    <div>
        <img src="/pics/Icon_Rechtskataster_Alle_Gesetze.jpg" /><br/>
        Alle Gesetze
</div>
</Link>
<Link to="/meinegesetze">
<div>
  
        <img src="/pics/Icon_Rechtskataster_Eigene_Gesetze.jpg" /><br/>
        Meine Gesetze

</div>
</Link>
<Link to="/aushang">
<div >
        <img src="/pics/Icon_Rechtskataster_Aushangpflicht.jpg" /><br/>
        Aushangpflichtige Gesetze
</div>
</Link>
<Link to="/unfallverhuetung">
<div>
    
        <img src="/pics/Icon_Rechtskataster_UVV.jpg" /><br/>
        DGUV-Vorschriften<br/>(Unfallverhütungsvorschriften)
        
</div>
</Link>
</div>

</div>
<p className={Styles.textbox}>
​Diese Applikation dient hauptsächlich Refratechnik Mitarbeitern in deutschen Standorten zur Erfüllung ihrer speziellen Aufgabe, Funktion  oder Beauftragung.

Zusätzlich bietet sie allen Mitarbeitern wichtige Informationen zu diversen (deutschen) Gesetzen und Vorschriften.

Für weitere Informationen bezüglich dieser Applikation und deren Funktionsweise klicken Sie bitte links auf die FAQ. 

Bei inhaltlichen Fragen wenden Sie sich bitte an <a href="https://teams.microsoft.com/l/chat/0/0?users=Axel.Westermann@refra.com">Axel Westermann</a>, bei technischen an <a href="https://teams.microsoft.com/l/chat/0/0?users=Tobias.Wolter@refra.com">Tobias Wolter</a>
</p>
</div>
    </div>
  );
}

export default Start;
