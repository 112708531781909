import Styles from './gesetzelist.module.css';
import { useNavigate } from "react-router-dom";
import { useMsal} from "@azure/msal-react";
import useStore from '../../state/useStore';
import React, { useState, useDeferredValue, useEffect } from "react";
import Loader from '../loader/loader';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ScreenSearchDesktopRounded } from '@mui/icons-material';



export default function Gesetzelist({gesetze}) {

let history = useNavigate();
//@ts-ignore
const {accounts,instance} = useMsal();
const searchtext = useStore(state => state.searchtext);
const [data,setData] = React.useState([]);


const deferredValue = useDeferredValue(searchtext);


useEffect(()=>{

  const d = gesetze.filter(f=> {
    if(searchtext.length > 3)
    {
      if(JSON.stringify(f).toLocaleLowerCase().indexOf(searchtext.toLocaleLowerCase()) > -1)
      {
        return true;
      }
      return false;
    }else
    {
      return true;
    }}).sort(function(a,b){

if(a[Object.keys(a)[10]] === undefined)
return -1;
if(b === undefined)
return -1;

    let aarr = a[Object.keys(a)[10]].split(".");
    let bbrr = b[Object.keys(b)[10]].split(".");
      let aa = new Date(aarr[2], aarr[1] - 1, aarr[0]);
      let bb = new Date(bbrr[2], bbrr[1] - 1, bbrr[0]);
      if(aa.toString() == "Invalid Date")
      {
        aa = new Date(0);
      }

if(bb.toString() == "Invalid Date")
bb = new Date(0);

      return bb -aa ;
    });;
  d.shift();
setData(d);
},[deferredValue,gesetze])



const result = data.map((g,i) => {
const url = "/gesetz/" + g[Object.keys(g)[13]];
return(<tr key={i}>
  <td>{g[Object.keys(g)[0]]}</td>
  <td>{g[Object.keys(g)[1]]}</td>
  <td>{g["Titel"]}</td>
  <td>{g[Object.keys(g)[10]]}</td>
  <td>{g[Object.keys(g)[11]]}</td>
  <td><div className={Styles.gesetzlink}><Link to={url} >Gesetz anzeigen</Link></div></td>
 
</tr>)
});

if(gesetze.length ===0)
{
  return(<Loader text="Keine Gesetze gefunden." />);
}
if(data.length ===0)
{
  return(<Loader text="Kein Gesetz gefunden." />);
}


  return (
    <div>
<table id="datatable" className="styled-table">
  <thead className={Styles.fixedheader}>
  <tr>
  <th>Kategorie</th><th>Ebene</th><th>Titel</th><th>Letzte Änderung</th><th>Datum des Gesetzes</th><th></th>
  </tr>
  </thead>
  <tbody>
{result}
  </tbody>
</table>

</div>
  );
}