import create from 'zustand';
import { Gesetze } from '../api/gesetze';
import {JobCodeGesetze} from '../api/jobcode';
import RefratechnikEmployeeJobCode from '../api/jobcode';
const store = localStorage.getItem("gesetzlist");
const init = (store) ? JSON.parse(store) : [];

export default create((set) => ({
  jobcode: "",
  searchtext: "",
  gesetze: init,
  stellenkuerzel:[],
  setStellenkuerzel: (text) => set(state => ({ stellenkuerzel: text})),
  setSearchText: (text) => set(state => ({ searchtext: text})),
  setGesetze: (ges) => set(state => ({ gesetze: ges})),
  loadGesetze: async () => 
  {
    const ges = await Gesetze();
  set(state => ({ gesetze: ges}))
  },
  loadStellenkuerzel: async () => 
  {
  const ges = await JobCodeGesetze();
  set(state => ({ stellenkuerzel: ges}))
  },
  loadJobCode: async () => 
  {
  const ges1 = await RefratechnikEmployeeJobCode();
  set(state => ({ jobcode: ges1.refratechnikEmployeeJobCode}));
  },
}
));
