import Gesetzelist from '../../../components/gesetzelist/gesetzelist';
import Header from '../../../components/header/header';
import { useEffect, useState } from 'react';
import useStore from '../../../state/useStore';
function MeineGesetze() {

  const loadGesetze = useStore(state => state.loadGesetze);
const loadStellenkuerzel = useStore(state => state.loadStellenkuerzel);
const stellenkuerzel = useStore(state => state.stellenkuerzel);
const jobcode = useStore(state => state.jobcode);
const gesetze = useStore(state => state.gesetze);
const loadJobCode = useStore(state => state.loadJobCode);
  useEffect(()=>{
    loadGesetze();
    loadStellenkuerzel();
    loadJobCode();
  },[]);


  if(gesetze.length == 0)
  return null;


const jobcodearr = jobcode.split(';');
  const meinegesetze = gesetze.filter(g => {

    const st = stellenkuerzel.find(f=> f.eintragsId == g["Eintrags-ID"]);
if(!st)
return false;

    let ret = false;
    jobcodearr.forEach(element => {
        if(JSON.stringify(st.stellenkuerzel).indexOf(element) > -1)
        {
          ret =  true;
        }
      });
      return ret;
  });


  return (
    <div className="App">
   
<Header/>
<div className='headerh1'>
<h1>Gesetze für Stellenkürzel: {jobcode}</h1>
</div>
<Gesetzelist gesetze={meinegesetze} />
    </div>
  );
}

export default MeineGesetze;
