import './App.css';
import Gesetzelist from './components/gesetzelist/gesetzelist';
import Header from './components/header/header';
import { useEffect, useState } from 'react';
import useStore from './state/useStore';
function App() {

  const loadGesetze = useStore(state => state.loadGesetze);
const loadStellenkuerzel = useStore(state => state.loadStellenkuerzel);
const gesetze = useStore(state => state.gesetze);
  useEffect(()=>{
    loadGesetze();
    loadStellenkuerzel();
  },[]);

  return (
    <div className="App">
   
<Header/>
<Gesetzelist gesetze={gesetze} />
    </div>
  );
}

export default App;
