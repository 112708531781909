import Styles from './singlegesetz.module.css';
import { Link, useNavigate } from "react-router-dom";
import { useMsal} from "@azure/msal-react";
import useStore from '../../../state/useStore';
import RefratechnikEmployeeJobCode from '../../../api/jobcode'
import React, { useState, useDeferredValue, useEffect } from "react";
import Header from '../../../components/header/header';
import {
    BrowserRouter as Router,
    Route,
    useParams
  } from "react-router-dom";


function SingleGesetz() {

    let { gesetzid } = useParams();
    const gesetze = useStore(state => state.gesetze);
    const loadGesetze = useStore(state => state.loadGesetze);
    const jobcode = useStore(state => state.jobcode);
    const stellenkuerzel = useStore(state => state.stellenkuerzel);
    const loadJobCode = useStore(state => state.loadJobCode);

    const loadStellenkuerzel = useStore(state => state.loadStellenkuerzel);
useEffect(()=> {
  loadStellenkuerzel();
  loadJobCode();
},[]);

React.useEffect(()=>{
    loadGesetze();
  },[]);

  const singlegesetz = gesetze.find(f=>{   
    return f[Object.keys(f)[13]] == gesetzid});

    if(!singlegesetz)
    {
        return null;
    }




const kuerzel = stellenkuerzel.find(f=> f.eintragsId == gesetzid);
const badges = kuerzel.stellenkuerzel.split(';').map(p=> {
  return(<div key={p} className={Styles.badge}>{p}</div>)
})


console.log(singlegesetz);

  return (
    <div className="App">
   
<Header/>
<table id="datatable" className="styled-table tblsingle">
  <thead>
  <tr>
  <th>Kategorie</th><th>Ebene</th><th>Titel</th><th>Letzte Änderung</th><th>Datum des Gesetzes</th>
  </tr>
  </thead>
  <tbody>
  <tr >
  <td>{singlegesetz[Object.keys(singlegesetz)[0]]}</td>
  <td>{singlegesetz[Object.keys(singlegesetz)[1]]}</td>
  <td>{singlegesetz["Titel"]}</td>
  <td>{singlegesetz[Object.keys(singlegesetz)[10]]}</td>
  <td>{singlegesetz[Object.keys(singlegesetz)[11]]}</td>

 
</tr>
  </tbody>
</table>
<div className={Styles.pagecontent}>
<h1>
   Dieses Gesetz wurde folgenden Stellenkürzel zugeordnet:
</h1>
<div>
  {badges}
</div>

<br/>
<div>
  <fieldset className={Styles.fieldsetbox}>
    <legend>Aushangpflicht oder Unfallverhütungsvorschrift</legend>

    <div>
      <input type="checkbox" id="Aushängepflichtig" name="Aushängepflichtig"
             checked/>
      <label htmlFor="Aushängepflichtig">Aushängepflichtig</label>
    </div>

    <div>
      <input type="checkbox" id="Unfallverhütungsvorschrift" name="Unfallverhütungsvorschrift"/>
      <label htmlFor="Unfallverhütungsvorschrift">Unfallverhütungsvorschrift</label>
    </div>
</fieldset>
<div><br/>
Zusatz-Info: {singlegesetz[Object.keys(singlegesetz)[4]]}
</div>
</div>
<br/><br/>
<div>
<Link to="/gesetze" >
  <img src="/pics/back.jpg" alt="Zurück" />
</Link>
  <a href={singlegesetz[Object.keys(singlegesetz)[8]]} target="_blank">
  <img src="/pics/uwonline.jpg" alt="Gesetz auf Umwelt-Online lesen" />
  </a>
</div>

</div>
    </div>
  );
}

export default SingleGesetz;
