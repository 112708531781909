import Gesetzelist from '../../../components/gesetzelist/gesetzelist';
import Header from '../../../components/header/header';
import { useEffect, useState } from 'react';
import useStore from '../../../state/useStore';
function Unfallverhuetung() {

  const loadGesetze = useStore(state => state.loadGesetze);
const loadStellenkuerzel = useStore(state => state.loadStellenkuerzel);
const stellenkuerzel = useStore(state => state.stellenkuerzel);
const gesetze = useStore(state => state.gesetze);
const loadJobCode = useStore(state => state.loadJobCode);
  useEffect(()=>{
    loadGesetze();
    loadStellenkuerzel();
    loadJobCode();
  },[]);


  if(gesetze.length == 0)
  return null;


const meinegesetze = gesetze.filter(g => {

    const st = stellenkuerzel.find(f=> f.eintragsId == g["Eintrags-ID"]);
if(st)
{
  if(st.unfallverhuetungsvorschrift ==true)
  {
    return true;
  }
}
     return false;

  });


  return (
    <div className="App">
   
<Header/>
<div className='headerh1'>
<h1>Unfallverhütungsvorschriften</h1>
</div>
<Gesetzelist gesetze={meinegesetze} />
    </div>
  );
}

export default Unfallverhuetung;
