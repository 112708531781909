import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import reportWebVitals from './reportWebVitals';
import Loader from './components/loader/loader';
import MeineGesetze from './pages/gesetz/meinegesetze/meinegesetze';
import { styled,  createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Start from './pages/start/start';
import SingleGesetz from './pages/gesetz/singlegesetz/singlegesetz';
import Unfallverhuetung from './pages/gesetz/unfallverhuetung/unfallverhuetung';
import AushangPage from './pages/gesetz/aushang/aushang';


const theme = createTheme({
  palette: {
    primary: {
      main: '#f15507'
    },
  }, typography: {
    fontFamily: [
      'Linotype Univers W01'
    ].join(','),
  }
});

//@ts-ignore
function ErrorComponent({error}) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <Loader text="REFRA Authentication.." />
}
//@ts-ignore
const pca = new PublicClientApplication(window.config.AUTHCONFIGURATION);

const authRequest = {
  scopes: ["openid", "profile"]
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
                  <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate 
interactionType={InteractionType.Redirect} 
authenticationRequest={authRequest} 
errorComponent={ErrorComponent} 
loadingComponent={LoadingComponent}>
        <ThemeProvider theme={theme}>
      <BrowserRouter>
    <Routes>
       <Route path="/" element={<Start />}/>  
       <Route path="/gesetz/:gesetzid" element={<SingleGesetz />}/> 
       <Route path="/gesetze" element={<App />}/>  
       <Route path='/meinegesetze' element={<MeineGesetze />} />
       <Route path='/unfallverhuetung' element={<Unfallverhuetung/>} />
       <Route path='/aushang' element={<AushangPage/>} />
   </Routes>
   </BrowserRouter>
   </ThemeProvider>
   </MsalAuthenticationTemplate>
  </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
