
export default async function RefratechnikEmployeeJobCode()
{
   const url = window.config.EMPLOYEEJOBCODEAPI + "/User"
      return fetch(url, {
        method: "GET",
        credentials: 'include'
            //@ts-ignore
      }).then((result)=>
      {
      //  return result.arrayBuffer();
       return result.json();        
      // result.json();
      });    
}

export async function JobCodeGesetze()
{
   const url = window.config.RECHTSKATASTERAPI + "/Rechtskataster"
      return fetch(url, {
        method: "GET"
            //@ts-ignore
      }).then((result)=>
      {
      //  return result.arrayBuffer();
       return result.json();        
      // result.json();
      });    
}