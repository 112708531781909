import React,{useEffect, useState} from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileDownloadOutlineIcon from '@mui/icons-material/FileDownloadRounded';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Styles from './header.module.css';
import { MenuItem } from '@mui/material';
import useStore from '../../state/useStore';
import { useMsal} from "@azure/msal-react";


//@ts-ignore
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { Menu } from '@mui/material';
import { Badge } from '@mui/material';
import { useNavigate,NavLink } from "react-router-dom";
//@ts-ignore
import NotificationsIcon from '@mui/icons-material/Notifications';
//@ts-ignore
import MoreIcon from '@mui/icons-material/MoreVert';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));



const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const {accounts,instance} = useMsal();

  let history = useNavigate();
  const jobcode = useStore(state => state.jobcode);
  const loadJobCode = useStore(state => state.loadJobCode);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
//@ts-ignore
  const searchtext = useStore((state) => state.searchtext);

  const setSearchtext = useStore((state) => state.setSearchText);
useEffect(()=>
{
  loadJobCode();
},[]);





  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
//@ts-ignore
  const handleMobileMenuOpen = (event:any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Suche .."
              inputProps={{ 'aria-label': 'search' }}
              value={searchtext}
              onChange={(e)=>{setSearchtext(e.target.value);}}
            />
          </Search>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <NavLink className={(navData) => (navData.isActive ? Styles.activeheaderlink : Styles.headerlink)}  to="/">
             Start
            </NavLink>
          </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  <NavLink  className={(navData) => (navData.isActive ? Styles.activeheaderlink : Styles.headerlink)} to="/meinegesetze">
            Meine Gesetze
            </NavLink>
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
             <NavLink   className={(navData) => (navData.isActive ? Styles.activeheaderlink : Styles.headerlink)} to="/gesetze">
             Alle Gesetze
            </NavLink>
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <NavLink   className={(navData) => (navData.isActive ? Styles.activeheaderlink : Styles.headerlink)} to="/aushang">
          Aushangpflichtige Arbeitsschutzgesetze
            </NavLink>
         
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <NavLink   className={(navData) => (navData.isActive ? Styles.activeheaderlink : Styles.headerlink)} to="/unfallverhuetung">
          DGUV-Vorschriften (Unfallverhütungsvorschriften)
            </NavLink>
       
          </Typography>
          Stellekürzel: {jobcode}
        </Toolbar>
      </AppBar>
    
    </Box>
  );
}