function csvJSON(csv){
  var lines=csv.split("\n");

  var result = [];

  // NOTE: If your columns contain commas in their values, you'll need
  // to deal with those before doing the next step 
  // (you might convert them to &&& or something, then covert them back later)
  // jsfiddle showing the issue https://jsfiddle.net/
  var headers=lines[0].split(";");

  for(var i=1;i<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(";");

      for(var j=0;j<headers.length;j++){
          obj[headers[j]] = currentline[j];
      }

      result.push(obj);

  }

  //return result; //JavaScript object
  return result; //JSON
}



export async function Gesetze()
{
  var myHeaders = new Headers();
  myHeaders.append('Content-Type','text/plain; charset=UTF-7');

    //@ts-ignore
 //   const url = "https://refraappapi.azurewebsites.net/CorsFetch?https://kataster.umwelt-online.de/api/register/export/csv-legacy?username=refratec&token=hLtGL9KiKj79&registerId=3869"; //window.config.BACKENDURL + "/source.js";
   //   const url = "https://localhost:44303/CorsFetch/umwerlonline?https://kataster.umwelt-online.de/api/register/export/csv-legacy?username=refratec&token=hLtGL9KiKj79&registerId=3869"; //window.config.BACKENDURL + "/source.js";
   const url = "https://refraappapi.azurewebsites.net/CorsFetch/umwerlonline?https://kataster.umwelt-online.de/api/register/export/csv-legacy?username=refratec&token=hLtGL9KiKj79&registerId=3869"; //window.config.BACKENDURL + "/source.js";
      return fetch(url, {
        method: "GET",
        headers: myHeaders
        //@ts-ignore
      }).then((result)=>
      {
      //  return result.arrayBuffer();
       return result.text();        
      // result.json();
      }).then((csv) => {
        return csvJSON(csv);
      });
    
}
